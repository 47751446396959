import { JsonProperty } from "@nstep-common/utils";

export class JwtTokenModel {

	@JsonProperty()
	aud!: string;

	@JsonProperty()
	iss!: string;

	@JsonProperty()
	exp!: number;

	@JsonProperty({ name: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' })
	role!: string;

	@JsonProperty({ name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name' })
	name!: string;

	@JsonProperty({ name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier' })
	nameIdentifier!: string;

	@JsonProperty({ name: 'Access', array: true, type: String })
	access!: string[];
}

export class ExtendedJwtTokenModel {	
	token!: JwtTokenModel;
	requiresTwoFactor!: boolean;
	requiresPasswordReset!: boolean;

	constructor(data: Partial<ExtendedJwtTokenModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}