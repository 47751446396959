import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BaseComponent, ChangePasswordModel, ChangePasswordModelValidator, ResetPasswordModel } from '@nstep-common/core';
import { AppSource, createProxy } from '@nstep-common/utils';
import { ValidationErrors } from 'fluentvalidation-ts';
import { flatten } from 'lodash';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent extends BaseComponent {

	validation: ValidationErrors<ChangePasswordModel> = {};
	errors: string[] = [];
	loading: boolean = false;
	requires2F: boolean = false;

	model: ChangePasswordModel = createProxy(new ChangePasswordModel(), {
		set: () => this.validate(this.model)
	});

	validate(value: ChangePasswordModel): void {
		const validator = new ChangePasswordModelValidator();
		this.validation = validator.validate(value);
	}

	userName!: string;
	appSource!: AppSource;

	constructor(private authService: AuthService,
		private router: Router) {
		super();

		const state = this.router.getCurrentNavigation()!.extras.state as { userName: string, appSource: AppSource };
		this.userName = state.userName;
		this.appSource = state.appSource;
	}

	onSubmit() {
		this.validate(this.model);

		if (Object.keys(this.validation).length != 0) {
			return;
		}

		this.loading = true;

		const resetModel: ResetPasswordModel = {
			userName: this.userName,
			oldPassword: this.model.oldPassword,
			newPassword: this.model.newPassword,
			twoFactorCode: this.model.twoFactorCode
		};

		this.subscriptions.push(this.authService
			.resetPassword(resetModel)
			.subscribe({
				next: (jwt) => {
					if (jwt.requiresTwoFactor) {
						this.requires2F = true;
					} else {
						this.router.navigate(['login']);
					}
				},
				error: (response: { [key: string]: string[] }) => {
					this.loading = false;

					const errors = flatten(Object.values(response)) as string[];

					this.errors = errors;
				}
			}));
	}

}
