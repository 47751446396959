import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { toast } from '@nstep-common/utils';
import { ArchiveDataService } from '@nstep-internal/shared';
import { chain, map } from 'lodash';

@Component({
	selector: 'app-export-archive',
	templateUrl: './export-archive.component.html'
})
export class ArchiveComponent extends BaseComponent implements OnInit {
	filters: any = {
		rationed: [
			new DropdownOption({ name: 'Yes', value: 0 }),
			new DropdownOption({ name: 'No', value: 1 })
		],
		years: [],
		posLocations: [],
		posOperators: [],
		entitlementCodes: [],
		entitlementTypes: []
	};

	searchResult: any = null;

	rationed: any;
	year: any;
	posLocationId: any;
	posOperatorId: any;
	entitlementTypeId: any;
	entitlementCodeId: any;

	isExporting = false;

	searchCard: (term: string, callback: (results: {
		obj: any;
		description: string;
		title: string;
	}[]) => void) => void = (term, callback) => {
		const getAmisCards$ = this.archivedDataService.getAmisCards(term)
			.subscribe(resp => {
				const results = map(resp, (item: any) => ({
					obj: item,
					description: '',
					title: item.value
				}));

				callback(results);
			});

		this.subscriptions.push(getAmisCards$);
	};

	constructor(private archivedDataService: ArchiveDataService) {
		super();
	}

	ngOnInit(): void {
		const filterOptions$ = this.archivedDataService.filterOptions().subscribe({
			next: (response: any) => {

				this.filters.years = chain(response.years as any[])
					.map(item => new DropdownOption({
						name: item,
						value: item
					}))
					.value();

				this.filters.posLocations = chain(response.posLocations as any[])
					.map(item => new DropdownOption({
						name: item.value,
						value: item.id
					}))
					.value();

				this.filters.posOperators = chain(response.posOperators as any[])
					.map(item => new DropdownOption({
						name: item.value,
						value: item.id
					}))
					.value();

				this.filters.entitlementCodes = chain(response.entitlementCodes as any[])
					.map(item => new DropdownOption({
						name: item.value,
						value: item.id
					}))
					.value();

				this.filters.entitlementTypes = chain(response.entitlementTypes as any[])
					.map(item => new DropdownOption({
						name: item.value,
						value: item.id
					}))
					.value();
			}
		});

		this.subscriptions.push(filterOptions$);
	}

	export(): void {
		this.isExporting = true;

		const downloadCSV = this.archivedDataService
			.downloadCSV({
				cardId: Number(this.searchResult?.id),
				rationed: Boolean(this.rationed),
				year: Number(this.year),
				posLocationId: Number(this.posLocationId),
				posOperatorId: Number(this.posOperatorId),
				entitlementCodeId: Number(this.entitlementCodeId),
				entitlementTypeId: Number(this.entitlementTypeId)
			})
			.subscribe({
				next: response => {
					const blob: Blob = response.body as Blob;
					const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

					const anchor = document.createElement('a');
					anchor.download = fileName;
					anchor.href = window.URL.createObjectURL(blob);
					anchor.click();

					toast('Success', 'File exported successfully!', 'green');
					this.isExporting = false;
				},
				error: () => {
					toast('Error', 'Failed to export file!', 'red');
					this.isExporting = false;
				}
			});

		this.subscriptions.push(downloadCSV);
	}
}

