import { JsonProperty } from "@nstep-common/utils";

export class AccountModel {
	@JsonProperty({ type: String, nullable: true })
	accessToken!: string | null;

	@JsonProperty({ type: String, nullable: true })
	refreshToken!: string | null;

	@JsonProperty({ type: Boolean })
	requiresTwoFactor!: boolean;

	@JsonProperty({ type: Boolean })
	requiresPasswordReset!: boolean;
	
	constructor(data: Partial<AccountModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class RefreshTokensModel {
	@JsonProperty({ type: String })
	accessToken!: string;

	@JsonProperty({ type: String })
	refreshToken!: string;

	constructor(data: Partial<RefreshTokensModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ResetPasswordModel {
	userName!: string;
	oldPassword!: string | null;
	newPassword!: string;
	twoFactorCode!: string | null;

	constructor(data: Partial<ResetPasswordModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ContactModel {
	@JsonProperty({ type: String, nullable: true })
	telephone!: string | null;

	
	@JsonProperty({ type: String, nullable: true })
	email!: string | null;

	constructor(data: Partial<ContactModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class TwoFactorModel {
	@JsonProperty({ type: Boolean })
	hasAuthenticator!: boolean;
	
	@JsonProperty({ type: Boolean })
	isTwoFactorEnabled!: boolean;

	@JsonProperty({ type: Number })
	recoveryCodesLeft!: number;

	constructor(data: Partial<TwoFactorModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AuthenticatorModel {
	@JsonProperty({ type: String })
	sharedKey!: string;
	
	@JsonProperty({ type: String })
	authenticatorUri!: string;

	constructor(data: Partial<AuthenticatorModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EnableAuthenticatorModel {
	@JsonProperty({ type: String })
	code!: string;

	constructor(data: Partial<EnableAuthenticatorModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}