import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any, PagedQueryParameter } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SystemService {

	constructor(private apiService: ApiService) { }

	updateTransactions(amisCardNumber: string): Observable<any> {
		return this.apiService.patchNoContent(`system/update-transactions/${amisCardNumber.toUpperCase()}`);
	}

	getExportHistory(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'system/export-history', { observe: 'response', params: model });
	}

	exportMatrix(matrixId: number): Observable<any> {
		return this.apiService.get(Any, 'system/export-matrix', { observe: 'response', responseType: 'blob', params: { id: matrixId } });
	}

	getImportHistory(model: PagedQueryParameter): Observable<any> {
		return this.apiService.get(Any, 'system/import-history', { observe: 'response', params: model });
	}
}