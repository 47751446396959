<h3 class="ui header">
	<i class="map marker alternate icon"></i>
	<div class="content">
		POS Locations
		<div class="sub header">POS</div>
	</div>
</h3>
<br />

<div class="ui stackable grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="posLocationPagedQueryModel.searchBy"
					(keydown.enter)="search()" />
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openAddPosLocationModal()">
				<i class="plus icon"></i>Add POS Location
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="posLocationsItemTemplate"
				[data]="mainTableData"
				[dataReady]="mainTableDataReady"
				[columns]="mainTableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #posLocationsItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a href="javascript:void(0);" [ngStyle]="{'color': item.enabled ? 'green' : 'red'}" (click)="openAddPosLocationModal(item)">{{item.name}}</a>
		</td>
		<td>{{item.telephone}}</td>
		<td>{{item.streetName}}</td>
		<td>{{item.city}}</td>
		<td>{{item.country}}</td>
		<td>
			<a href="javascript:void(0);" (click)="openDisablePosLocationModal(item)">Disable</a>
		</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="mainModalIsLoading" #posLocationModal [noClose]="noMainModalClose">
	<ng-container header>{{editModal ? 'Modify POS Location: ' + selectedPosLocation.name : 'Add POS Location'}}</ng-container>

	<ng-container content>
		<div class="ui three top attached steps">
			<div class="step" [class.active]="currentStep === 1"
				[class.link]="editModal"
				(click)="changeStep(PosLocationSteps.PosLocationInformation)">
				<i class="map marker alternate icon"></i>
				<div class="content">
					<div class="title">Step 1</div>
					<div class="description">POS Location Information</div>
				</div>
			</div>
			<div class="step" [class.active]="currentStep === 2"
				[class.link]="editModal"
				(click)="changeStep(PosLocationSteps.EntitlementTypes)">
				<i class="cube icon"></i>
				<div class="content">
					<div class="title">Step 2</div>
					<div class="description">Entitlement Types</div>
				</div>
			</div>

			<div class="step" [class.active]="currentStep === 3"
				[class.link]="editModal"
				(click)="changeStep(PosLocationSteps.PosOperators)">
				<i class="users icon"></i>
				<div class="content">
					<div class="title">Step 3</div>
					<div class="description">POS Operators</div>
				</div>
			</div>
		</div>

		<div class="ui attached segment">
			<div *ngIf="currentStep === 1" class="ui form">
				<div class="three fields">

					<div class="field" app-field [error]="posLocationModelValidation | path: 'posLocationName'">
						<label>POS Location Name*</label>
						<input type="text" [(ngModel)]="posLocationModel.posLocationName" app-trim>
					</div>

					<div class="field" app-field [error]="posLocationModelValidation | path: 'countryId'">
						<label>Country*</label>
						<sm-dropdown class="ui selection dropdown"
							[(value)]="posLocationModel.countryId"
							[options]="countryDropdownValues"
							(valueChange)="onCountryValueChange()">
							<div class="text"></div>
							<i class="dropdown icon"></i>
						</sm-dropdown>
					</div>

					<div class="field" app-field [error]="posLocationModelValidation | path: 'telephone'">
						<label>Telephone</label>
						<input type="text" [(ngModel)]="posLocationModel.telephone" app-trim>
					</div>

				</div>
				<div class="three fields">
					<div class="field" app-field [error]="posLocationModelValidation | path: 'streetName'">
						<label>Street Name*</label>
						<input type="text" [(ngModel)]="posLocationModel.streetName" app-trim>
					</div>

					<div class="field" app-field [error]="posLocationModelValidation | path: 'buildingNumber'">
						<label>Building Number</label>
						<input type="text" [(ngModel)]="posLocationModel.buildingNumber" app-trim>
					</div>

					<div class="field" app-field [error]="posLocationModelValidation | path: 'postalCode'">
						<label>Postal Code*</label>
						<input type="text" [(ngModel)]="posLocationModel.postalCode" app-trim>
					</div>
				</div>
				<div class="three fields">
					<div class="field" app-field [error]="posLocationModelValidation | path: 'cityOrTown'">
						<label>City / Town*</label>
						<input type="text" [(ngModel)]="posLocationModel.cityOrTown" app-trim>
					</div>

					<div class="field">
						<label>Opening Hour*</label>
						<div class="two fields">
							<div class="field" app-field [error]="posLocationModelValidation | path: 'openingHour'">
								<sm-calendar class="ui calendar"
									[settings]="workingHoursCalendarSettings"
									[(value)]="posLocationModel.openingHour"
									(valueChange)="onOpeningHourValueChange()">
									<div class="ui fluid input left icon">
										<i class="time icon"></i>
										<input type="text" placeholder="Start Time" />
									</div>
								</sm-calendar>
							</div>

							<div *ngIf="posLocationModel.localOpeningHour" class="field">
								<p style="margin-top: 5px;">Local: {{posLocationModel.localOpeningHour | date: "H:mm" }}</p>
							</div>
						</div>
					</div>
					<div class="field">
						<label>Closing Hour*</label>
						<div class="two fields">
							<div class="field" app-field [error]="posLocationModelValidation | path: 'closingHour'">
								<sm-calendar class="ui calendar"
									[settings]="workingHoursCalendarSettings"
									[(value)]="posLocationModel.closingHour"
									(valueChange)="onClosingHourValueChange()">
									<div class="ui fluid input left icon">
										<i class="time icon"></i>
										<input type="text" placeholder="End Time" />
									</div>
								</sm-calendar>
							</div>
							<div *ngIf="posLocationModel.localClosingHour" class="field">
								<p style="margin-top: 5px;">Local: {{posLocationModel.localClosingHour | date: "H:mm" }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="three fields">
					<div class="field" app-field [error]="posLocationModelValidation | path: 'posLocationTypeIds'">
						<label>POS Location Type*</label>
						<div class="ui segment" style="margin: 0; overflow: auto; height: 6rem;">
							<div class="ui list">
								<div class="item" *ngFor="let type of posLocationTypes">
									<div class="ui checkbox" style="margin-top: 0px;">
										<input type="checkbox" id="type-{{type.id}}" [(ngModel)]="type.isChecked" (change)="onPosLocationTypeValueChange(type)">
										<label for="type-{{type.id}}">{{type.name }}</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="field">
						<div class="ui form">
							<div class="field">
								<label>HQs*</label>
								<sm-dropdown class="ui selection dropdown"
									[(value)]="posLocationModel.headquarterId"
									[options]="headquarterDropdownValues"
									(valueChange)="onHeadquarterValueChange()">
									<div class="text"></div>
									<i class="dropdown icon"></i>
								</sm-dropdown>
							</div>
							<div class="field">
								<button class="ui fluid basic button" style="margin-top: 0.5rem;"
									[disabled]="!isPosLocationModelValid"
									(click)="checkCurrentAssociation()">
									Assign Headquarter
								</button>
							</div>
						</div>
					</div>

					<div class="field">
						<div class="ui checkbox">
							<input type="checkbox" id="fastScanCapability" [disabled]="posLocationFastScanLocked" [ngModel]="posLocationModel.isFastScan" (change)="onPosLocationFastScanValueChange($event)">
							<label for="fastScanCapability">Fast Scan Capability</label>
						</div>
					</div>
				</div>

				<div class="ui divider"></div>

				<app-table-view
					[itemTemplate]="hqAssociationItemTemplate"
					[data]="hqAssociationsTableData"
					[dataReady]="hqAssociationsTableDataReady"
					[columns]="hqAssociationsTableColumns"
					[noSearch]="true">
				</app-table-view>
				<h4>All fields marked with * are mandatory</h4>
			</div>

			<div *ngIf="currentStep === 2" class="ui form">
				<div class="field">
					<app-table-view
						[itemTemplate]="entitlementTypesItemTemplate"
						[data]="entitlementTypesTableData"
						[dataReady]="entitlementTypesTableDataReady"
						[columns]="entitlementTypesTableColumns"
						[noSearch]="true">
					</app-table-view>
				</div>

				<div class="fields">
					<div class="field">
						<button class="ui basic button"
							(click)="openEntitlementTypeAssignmentModal()">
							Assign Entitlement Type
						</button>
					</div>
				</div>
			</div>

			<div *ngIf="currentStep === 3" class="ui form">
				<div *ngIf="posLocationModel.isFastScan && posLocationModel.groupPropertiesValidation === PosLocationSteps.PosOperators && !posOperatorsFastScanChecked" class="ui negative message">
					<ul class="list">
						<li>{{ posLocationModelValidation['posOperatorsAssociations'] }}</li>
					</ul>
				</div>
				<div class="field">
					<app-table-view
						[itemTemplate]="posOperatorsItemTemplate"
						[data]="posOperatorsTableData"
						[dataReady]="posOperatorsTableDataReady"
						[columns]="posLocationModel.isFastScan ? posOperatorsFasScanTableColumns : posOperatorsTableColumns"
						[noSearch]="true">
					</app-table-view>
				</div>

				<div class="fields">
					<div class="field">
						<button class="ui basic button"
							(click)="openPosOperatorEnrollmentModal()">
							Enroll POS Operator
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="currentStep === 1 ? posLocationModal.toggle() : back()">
			{{ currentStep === 1 ? 'Cancel' : 'Back' }}
			<i *ngIf="currentStep === 1" class="cancel icon"></i>
			<i *ngIf="currentStep !== 1" class="arrow left icon"></i>
		</button>

		<button class="ui primary right labeled icon button"
			[disabled]="currentStep === 1 && (!isPosLocationModelValid || !posLocationModel.headquarterAssociations.length) || currentStep === 2 && !entitlementTypesTableData.length || currentStep === 3 && (!isPosLocationModelValid || !posOperatorsTableData.length)"
			(click)="currentStep === 3 ? save() : next()">
			{{ currentStep === 3 ? 'Save' : 'Next' }}
			<i *ngIf="currentStep !== 3" class="arrow right icon"></i>
			<i *ngIf="currentStep === 3" class="save icon"></i>
		</button>
	</ng-container>


</sm-modal>

<ng-template #hqAssociationItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a *ngIf="!item.endDate && !item.informationId"
				href="javascript:void(0);"
				data-tooltip="Delete">
				<i class="times icon" (click)="removeElement(item, 'Hq')"></i>
			</a>
			{{item.headquarterName}}
		</td>

		<td *ngIf="item.canModify">
			<a href="javascript:void(0);"
				data-tooltip="Modify start date"
				(click)="openModifyDateModal(item)">
				{{item.startDate | date : 'dd/MM/yyyy'}}
			</a>
		</td>
		<td *ngIf="!item.canModify">
			{{item.startDate | date : 'dd/MM/yyyy'}}
		</td>

		<td>
			<ng-container *ngIf="!item.endDate && !item.informationId">-</ng-container>
			<a href="javascript:void(0);" (click)="openRevokeHqModal(item)">{{ (item.endDate | date : 'dd/MM/yyyy') === '31/12/9999' ? 'Revoke' : item.endDate | date : 'dd/MM/yyyy'}}</a>
		</td>
	</tr>
</ng-template>

<ng-template #entitlementTypesItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td [ngStyle]="{'color': item.disabled ? 'orange' : 'black'}">
			<a *ngIf="!item.posLocationId"
				href="javascript:void(0);"
				data-tooltip="Delete">
				<i class="times icon" (click)="removeElement(item, 'EntitlementType')"></i>
			</a>
			{{item.entitlementTypeName}}
		</td>
		<td [ngStyle]="{'color': item.disabled ? 'orange' : 'black'}">{{item.headquarterName}}</td>
		<td>
			<i class="icon" [ngClass]="item.rationingTypeId === 1 ? 'check' : 'times'"></i>
		</td>
		<td>
			<ng-container *ngIf="!item.posLocationId">-</ng-container>
			<ng-container *ngIf="item.posLocationId">
				<a href="javascript:void(0);" (click)="item.disabled = !item.disabled">{{ !item.disabled ? 'Revoke' : 'Enable'}}</a>
			</ng-container>
		</td>
	</tr>
</ng-template>

<ng-template #posOperatorsItemTemplate let-item="item">
	<tr style="text-align: center;" [class.positive]="!item.id" [class.warning]="item.isModified">
		<td *ngIf="posLocationModel.isFastScan" [class.negative]="!posOperatorsFastScanChecked">
			<div class="field">
				<div class="ui checkbox">
					<input type="checkbox" [disabled]="posOperatorsFastScanLocked" [ngModel]="item.isFastScan" (change)="onPosOperatorFastScanValueChange(item, $event)">
					<label></label>
				</div>
			</div>
		</td>
		<td>
			<a *ngIf="!item.poslocationId && !item.endDate"
				href="javascript:void(0);"
				data-tooltip="Delete">
				<i class="times icon" (click)="removeElement(item, 'Enroll')"></i>
			</a>
			<a
				href="javascript:void(0);"
				[ngStyle]="{'color': item.contact ? 'green' : 'red'}"
				[attr.data-tooltip]="item.contact ? 'Update Contact' : 'Add Contact'"
				(click)="openAddContactModal(item)">
				{{item.amisCardNumber}}
			</a>
			<a *ngIf="item.isActive && item.contact"
				href="javascript:void(0);"
				data-tooltip="Remove Contact"
				(click)="openPOCConfirmationModal(item)">
				<i class="address card outline icon"></i>
			</a>
		</td>

		<td *ngIf="item.canModify">
			<a href="javascript:void(0);"
				data-tooltip="Modify start date"
				(click)="openPosOperatorModifyDateModal(item)">
				{{item.startDate | date : 'dd/MM/yyyy'}}
			</a>
		</td>
		<td *ngIf="!item.canModify">
			{{item.startDate | date : 'dd/MM/yyyy'}}
		</td>

		<td>
			<ng-container *ngIf="!item.poslocationId && !item.endDate">-</ng-container>

			<a href="javascript:void(0);" [ngStyle]="{'color': item.endDateInThePast  ? 'red' : null}"
				(click)="openUnenrollPosOperatorModal(item)">
				{{ (item.endDate | date : 'dd/MM/yyyy') === '31/12/9999' ? 'Unenroll' : item.endDate | date : 'dd/MM/yyyy'}}
			</a>
		</td>
	</tr>
</ng-template>

<sm-modal #addPosOperatorContactModal [closeIconColor]="'primary'" [isMini]="true" (closeEvent)="closePosOperatorContactModal()">
	<ng-container header>{{editContactModal ? 'Update Contact: ' + selectedPosOperator.amisCardNumber : 'Add Contact: ' + selectedPosOperator.amisCardNumber}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactName'">
				<label>Name*</label>
				<input type="text" [(ngModel)]="posLocationModel.posOperatorContactName" app-trim>
			</div>

			<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactEmail'">
				<label>Email*</label>
				<input type="text" [(ngModel)]="posLocationModel.posOperatorContactEmail" app-trim>
			</div>

			<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactPhoneNumber'">
				<label>Phone Number*</label>
				<input type="text" [(ngModel)]="posLocationModel.posOperatorContactPhoneNumber" app-trim>
			</div>

			<div class="inline field" app-field [error]="posLocationModelValidation | path: 'isPrimaryPosOperatorContact'">
				<div class="ui checkbox">
					<input type="checkbox"
						[disabled]="!this.posLocationModel.noPrimaryContactSelected && !!this.posOperatorWithPrimaryContact && this.selectedPosOperator.amisCardNumber !== this.posOperatorWithPrimaryContact.amisCardNumber"
						id="primaryPosOperatorContact"
						[(ngModel)]="posLocationModel.isPrimaryPosOperatorContact">
					<label for="primaryPosOperatorContact">Is Primary Contact</label>
				</div>

				<a *ngIf="!this.posLocationModel.noPrimaryContactSelected && !!this.posOperatorWithPrimaryContact && this.selectedPosOperator.amisCardNumber !== this.posOperatorWithPrimaryContact.amisCardNumber"
					[attr.data-tooltip]="posOperatorWithPrimaryContact? 'Primary contact is already assigned for ' + posOperatorWithPrimaryContact.amisCardNumber : null">
					<i class="question circle outline icon"></i>
				</a>
			</div>

			<p>All fields marked with * are mandatory</p>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closePosOperatorContactModal()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isPosLocationModelValid"
			(click)="assginPosOperatorContact()">
			Assign
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="sideModalIsLoading" #commonModifyDateModal [closeIconColor]="'primary'" [isMini]="true" (closeEvent)="closeCommonModifyDateModal(dateTypeModal)">
	<ng-container *ngIf="dateTypeModal === 'Disable'" header>Disable Unit - {{selectedPosLocation.name}}</ng-container>
	<ng-container *ngIf="dateTypeModal === 'Assign'" header>Assign {{selectedHeadquarter}} to {{posLocationModel.posLocationName}}</ng-container>
	<ng-container *ngIf="dateTypeModal === 'ModifyHqDate'" header>Modify Start Date - {{selectedHeadquarter}}</ng-container>
	<ng-container *ngIf="dateTypeModal === 'Revoke'" header>Revoke - {{selectedHeadquarter}}</ng-container>
	<ng-container *ngIf="dateTypeModal === 'ModifyPosOperatorDate'" header>Modify Start Date - {{selectedPosOperator.amisCardNumber}}</ng-container>
	<ng-container *ngIf="dateTypeModal === 'Unenroll'" header>Unenroll - {{selectedPosOperator.amisCardNumber}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="posLocationModelValidation | path: 'commonDateHolder'">
				<label>{{ dateTypeModal === 'Disable' || dateTypeModal === 'Revoke' || dateTypeModal === 'Unenroll' ? 'End Date*:' : 'Start Date*:' }}</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="posLocationModel.commonDateHolder"
					[minValue]="selectedPosOperator.startDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closeCommonModifyDateModal(dateTypeModal)">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isPosLocationModelValid"
			(click)="saveDate()">
			{{ dateTypeModal === 'Assign' ? 'Assign' : 'Save' }}
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #entitlementTypeModal [closeIconColor]="'primary'" [isTiny]="true" (closeEvent)="closeEntitlementTypeAssignmentModal()">
	<ng-container header>Assign entitlement type</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="posLocationModelValidation | path: 'entitlementTypeHeadquarterId'">
				<label>Headquarter*</label>
				<sm-dropdown class="ui selection dropdown"
					[(value)]="posLocationModel.entitlementTypeHeadquarterId"
					[options]="entitlementTypesHqDropdownValues"
					(valueChange)="onEntitlementTypedHqValueChange()">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field [error]="posLocationModelValidation | path: 'entitlementTypeId'">
				<label>Entitlement type*</label>
				<sm-dropdown class="ui selection dropdown"
					[(value)]="posLocationModel.entitlementTypeId"
					[options]="entitlementTypesDropdownValues">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<p>All fields marked with * are mandatory</p>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closeEntitlementTypeAssignmentModal()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isPosLocationModelValid"
			(click)="assignEntitlementTypeHq()">
			Assign
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal #posOperatorEnrollmentModal [closeIconColor]="'primary'" [isMini]="true" (closeEvent)="closePosOperatorEnrollmentModal()">
	<ng-container header>Enroll POS Operator</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="posLocationModelValidation | path: 'amisCardNumber'">
				<label>AMIS Card Number*</label>
				<sm-search #posLocationSearch class="ui search"
					(valueChange)="onAmisCardValueChange($event)"
					[settings]="searchSettings">
					<div class="ui left icon input">
						<input class="prompt" type="text" placeholder="Search Amis Card Number">
						<i class="database icon"></i>
					</div>
				</sm-search>
			</div>

			<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorStartDate'">
				<label>Start Date*:</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="posLocationModel.posOperatorStartDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>

			<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorEndDate'">
				<label>End Date:</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="posLocationModel.posOperatorEndDate"
					[minValue]="posLocationModel.posOperatorStartDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>

			<div class="inline field">
				<div class="ui checkbox">
					<input type="checkbox"
						id="POCCreation"
						[(ngModel)]="posLocationModel.displayPOCForm">
					<label for="POCCreation">Enable POC Creation</label>
				</div>
			</div>

			<div *ngIf="posLocationModel.displayPOCForm">
				<div class="ui divider"></div>

				<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactName'">
					<label>Name*</label>
					<input type="text" [(ngModel)]="posLocationModel.posOperatorContactName" app-trim>
				</div>

				<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactEmail'">
					<label>Email*</label>
					<input type="text" [(ngModel)]="posLocationModel.posOperatorContactEmail" app-trim>
				</div>

				<div class="field" app-field [error]="posLocationModelValidation | path: 'posOperatorContactPhoneNumber'">
					<label>Phone Number*</label>
					<input type="text" [(ngModel)]="posLocationModel.posOperatorContactPhoneNumber" app-trim>
				</div>

				<div class="inline field" app-field [error]="posLocationModelValidation | path: 'isPrimaryPosOperatorContact'">
					<div class="ui checkbox">
						<input type="checkbox"
							[disabled]="!this.posLocationModel.noPrimaryContactSelected && !!this.posOperatorWithPrimaryContact && this.selectedPosOperator.amisCardNumber !== this.posOperatorWithPrimaryContact.amisCardNumber"
							id="primaryPosOperatorContact"
							[(ngModel)]="posLocationModel.isPrimaryPosOperatorContact">
						<label for="primaryPosOperatorContact">Is Primary Contact</label>
					</div>

					<a *ngIf="!this.posLocationModel.noPrimaryContactSelected && !!this.posOperatorWithPrimaryContact && this.selectedPosOperator.amisCardNumber !== this.posOperatorWithPrimaryContact.amisCardNumber"
						[attr.data-tooltip]="posOperatorWithPrimaryContact? 'Primary contact is already assigned for ' + posOperatorWithPrimaryContact.amisCardNumber : null">
						<i class="question circle outline icon"></i>
					</a>
				</div>
			</div>

			<p>All fields marked with * are mandatory</p>
		</div>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closePosOperatorEnrollmentModal()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isPosLocationModelValid"
			(click)="enrollPosOperator()">
			Enroll
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>


<sm-modal #posOperatorContactConfirmModal [isTiny]="true">
	<ng-container header>
		Confirm
	</ng-container>

	<ng-container content>
		<p>{{confirmMessage}}</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="posOperatorContactConfirmModal.toggle()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui green right labeled icon button"
			(click)="confirm('Contact')">
			Yes
			<i class="check icon"></i>
		</button>
	</ng-container>
</sm-modal>