<h3 class="ui header">
	<i class="file upload icon"></i>
	<div class="content">
		Import History
		<div class="sub header">System</div>
	</div>
</h3>
<br />

<div class="ui from">
	<div class="field">
		<app-table-view
			[data]="tableData"
			[dataReady]="tableDataReady"
			[columns]="tableColumns"
			[noSearch]="true">
		</app-table-view>
	</div>
</div>