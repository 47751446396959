import { Component, OnInit } from '@angular/core';
import {
	ExportDataRequest,
	FilterChangeModel,
	FilterOperator,
	FilterType,
	ReportBaseComponent,
	ReportColumn,
	ReportColumnSortDirection,
	ReportingViews,
	ReportPagedQueryParameter,
	ReportSettingDefaultDataDto,
	ReportSettings,
	ReportSettingsDto,
	ReportSettingsShortDto,
	TableColumn
} from '@nstep-common/core';
import { PagedQueryParameter, PaginationModel, toast } from '@nstep-common/utils';
import { ReportingTableItem } from '@nstep-internal/pages';
import { GridService, ReportingService, ReportService, ReportsManagementService } from '@nstep-internal/shared';
import { camelCase, chain, mapKeys, startCase } from 'lodash';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-reporting',
	templateUrl: './reporting.component.html',
})
export class ReportingComponent extends ReportBaseComponent implements OnInit {

	ReportingViews = ReportingViews;
	currentReportingView = ReportingViews.Main;
	loading = false;

	tableData: any[] = [];
	tableDataReady = false;

	tableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isHeaderCentered: true },
		{ name: 'Available', key: 'availableRoles', isHeaderCentered: true },
		{ name: 'Country', key: 'country', isHeaderCentered: true },
		{ name: 'Description', key: 'description', isHeaderCentered: true }
	];

	pagedQueryModel: PagedQueryParameter = {
		itemsPerPage: 10,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	};

	reportData: any[][] = [];
	reportDataReady = false;
	reportColumns: ReportColumn[] = [];

	selectedTableItem = new ReportingTableItem();
	reportSettings = new ReportSettings();

	reportPagedQueryModel = new ReportPagedQueryParameter({
		page: 1,
		itemsPerPage: 10
	});

	reportPagination = new PaginationModel();

	constructor(private reportService: ReportService,
		private reportsManagementService: ReportsManagementService,
		private reportingService: ReportingService,
		private gridService: GridService) {
		super();
	}

	ngOnInit(): void {
		this.getDefaultData();
		this.getReports();
	}

	private getReports(): void {
		const getReportsSubscription$ = this.reportService.getReports(this.pagedQueryModel).subscribe({
			next: response => {

				this.tableData = chain(response.body as ReportSettingsShortDto[])
					.map(e => new ReportingTableItem({
						id: e.id,
						name: e.name,
						availableRoles: e.roles.flatMap(d => d.roleName),
						country: e.nationName,
						description: e.description
					}))
					.value();

				this.tableDataReady = true;
			},
			error: () => {
				this.tableDataReady = true;
			}
		});

		this.subscriptions.push(getReportsSubscription$);
	}

	private getDefaultData(): void {
		const getDefaultDataSubscription$ = this.reportsManagementService.getDefaultData().subscribe({
			next: (response: ReportSettingDefaultDataDto) => {

				const endpoints = chain(response.reportTemplates)
					.flatMap(d => d.headers)
					.filter(d => d.filterType === FilterType.List)
					.map(d => d.endpoint!)
					.uniq()
					.value();

				const observables = endpoints.map(f => this.getColumnFilterCollection(f, this.gridService));

				const subscriptionForkJoin$ = forkJoin(observables).subscribe(responses => {

					const results: { [key: string]: string[] } = {};
					responses.forEach((values, index) => { results[endpoints[index]] = values; });

					response.reportTemplates.forEach(template => {
						this.reportTemplateColumns[template.id] = template.headers.map((column, index) => new ReportColumn({
							key: camelCase(column.name),
							name: startCase(column.name),
							isDate: column.isDate,
							orderNumber: index + 1,
							filterType: column.filterType,
							validOperators: column.validFilters,
							agregateOperator: column.filterType === FilterType.Basic ? FilterOperator.AND : FilterOperator.OR,
							assignedFilters: this.getDefaultFilters(column.filterType, results[column.endpoint!], camelCase(column.name) === 'timestamp')
						}));
					});
				});

				this.subscriptions.push(subscriptionForkJoin$);
			}
		});

		this.subscriptions.push(getDefaultDataSubscription$);
	}

	onEditAction(item: ReportingTableItem): void {
		this.selectedTableItem = item;
		this.reportPagedQueryModel = new ReportPagedQueryParameter({
			page: 1,
			itemsPerPage: 10
		});

		this.loading = true;
		this.currentReportingView = ReportingViews.Report;

		const getReportSettingsSubscription$ = this.reportService.getReportSettings(this.selectedTableItem.id!).subscribe({
			next: (response: ReportSettingsDto) => {

				this.reportSettings = new ReportSettings({
					templateId: response.templateId,
					canHideColumns: response.reportSettingsDetails.canHideColumns,
					canExport: response.reportSettingsDetails.canExport,
					canReorderColumns: response.reportSettingsDetails.canReorderColumns,
					canSort: response.reportSettingsDetails.canSort,
					canFilter: response.reportSettingsDetails.canFilter
				});

				const defaultTemplateColumns = this.reportTemplateColumns[this.reportSettings.templateId!];

				const proccessedReportColumnsSettings = this.getProccessedReportColumnsSettings(defaultTemplateColumns, response.reportSettingsDetails.columns);
				this.reportColumns = proccessedReportColumnsSettings.reportColumns;
				this.reportPagedQueryModel.sortList.push(...proccessedReportColumnsSettings.sortList);
				this.reportPagedQueryModel.filterList.push(...proccessedReportColumnsSettings.filterList);

				this.getReportTemplate(this.reportSettings.templateId);
			}
		});

		this.subscriptions.push(getReportSettingsSubscription$);
	}

	private getReportTemplate(templateId: number): void {
		const getReportTemplateSubscription$ = this.reportingService.getReportTemplate(templateId, this.reportPagedQueryModel).subscribe({
			next: response => {
				const paginationHeader = JSON.parse(response.headers.get('X-Pagination'));
				this.reportPagination = mapKeys(paginationHeader, (v, k) => camelCase(k)) as PaginationModel;

				this.reportData = this.getTemplatePaginatedData(response.body, templateId);

				this.loading = false;
				this.reportDataReady = true;
			},
			error: () => {
				this.reportDataReady = true;
				this.loading = false;
			}
		});

		this.subscriptions.push(getReportTemplateSubscription$);
	}

	pageChange(page: { pageSize: number, currentPage: number, totalPages: number }): void {
		this.reportPagedQueryModel.page = page.currentPage;
		this.reportPagedQueryModel.itemsPerPage = page.pageSize;

		this.reportDataReady = false;
		this.reportData = [];

		this.getReportTemplate(this.reportSettings.templateId!);
	}

	exportExcelTemplate(): void {
		this.loading = true;
		const model = new ExportDataRequest({
			reportName: this.selectedTableItem.name!,
			columns: this.reportColumns.map(d => d.name.replace(/\s/g, '')),
			filterList: this.reportPagedQueryModel.filterList,
			sortList: this.reportPagedQueryModel.sortList
		});

		const exportReportTemplateSubscription$ = this.reportingService.exportReportTemplate(this.reportSettings.templateId!, model).subscribe({
			next: response => {
				const blob: Blob = response.body as Blob;
				const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

				const anchor = document.createElement('a');
				anchor.download = fileName;
				anchor.href = window.URL.createObjectURL(blob);
				anchor.click();

				this.loading = false;
				toast('Success', 'File exported successfully!', 'green');
			},
			error: () => {
				this.loading = false;
				toast('Error', 'Failed to export file!', 'red');
			}
		});

		this.subscriptions.push(exportReportTemplateSubscription$);
	}

	sortByCol(column: ReportColumnSortDirection): void {
		this.reportDataReady = false;
		this.reportData = [];
		this.processColumnSortation(column, this.reportColumns, this.reportPagedQueryModel);

		this.getReportTemplate(this.reportSettings.templateId!);
	}

	filterChange(value: FilterChangeModel): void {
		this.reportDataReady = false;
		this.reportData = [];
		this.proccessColumnFilters(value, this.reportPagedQueryModel);

		this.getReportTemplate(this.reportSettings.templateId!);
	}
}