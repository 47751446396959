<h3 class="ui header">
	<i class="mobile alternate icon"></i>
	<div class="content">
		POS Devices
		<div class="sub header">POS</div>
	</div>
</h3>
<br />

<div class="ui stackable  grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()">
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openAddPosDeviceModal()">
				<i class="plus icon"></i>Add POS Device
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="posDeviceItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #posDeviceItemTemplate let-item="item">
	<tr>
		<td style="text-align: center;">
			<a href="javascript:void(0);" (click)="onUserAction(item, 'Edit')">{{item.posDeviceName}}</a>
		</td>
		<td>{{item.deviceIdentifier}}</td>
		<td>{{item.posLocationName}}</td>
		<td style="text-align: center;">
			<a href="javascript:void(0);" (click)="onUserAction(item, item.action)">{{item.action}}</a>
		</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #posDevicesModal (closeEvent)="close()" [isTiny]="true">
	<ng-container header>{{editModal ? 'Modify POS Device: ' + posDevice.posDeviceName : 'Add POS Device'}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="validation | path: 'posDeviceName'">
				<label>Name*</label>
				<div class="ui fluid input">
					<input type="text" name="posDeviceName" [(ngModel)]="posDevice.posDeviceName" app-trim>
				</div>
			</div>

			<div class="field" app-field [error]="validation | path: 'posLocationId'">
				<label>POS Location*</label>
				<sm-dropdown class="ui fluid selection dropdown label"
					[class.loading]="!posLocationsDropdownDataReady"
					[(value)]="posDevice.posLocationId"
					[options]="posLocationsDropdownValues">
					<div class="text"></div>
					<i class="dropdown icon"></i>
				</sm-dropdown>
			</div>

			<div class="field" app-field [error]="validation | path: 'deviceIdentifier'">
				<label>Device Identifier*</label>
				<div class="ui fluid input">
					<input type="text" name="deviceIdentifier" [(ngModel)]="posDevice.deviceIdentifier" app-trim>
				</div>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>