import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RolesService {

	constructor(private apiService: ApiService) { }

	getRoles(): Observable<any> {
		return this.apiService.get(Any, 'roles', { observe: 'response' });
	}

	getPermissions(): Observable<any> {
		return this.apiService.get(Any, 'roles/permissions');
	}

	getRolePermissions(id: number): Observable<any> {
		return this.apiService.get(Any, `roles/${id}/permissions`);
	}

	getRoleUsers(id: number): Observable<any> {
		return this.apiService.get(Any, `roles/${id}/users`);
	}

	createRole(model: any): Observable<any> {
		return this.apiService.post(Any, 'roles', model);
	}

	updateRole(model: any): Observable<any> {
		return this.apiService.put(Any, 'roles', model);
	}

	enableRole(id: number): Observable<any> {
		return this.apiService.putNoContent(`roles/${id}/enable`);
	}

	disableRole(id: number): Observable<any> {
		return this.apiService.putNoContent(`roles/${id}/disable`);
	}

	deleteRole(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`roles/${id}`);
	}

	getInternalApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/internal-api');
	}

	getPublicApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/public-api');
	}

	getClientApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/client-api');
	}

	getInternalWebAppPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/internal-web-app');
	}

	getPublicWebAppPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/public-web-app');
	}

	invalidateCache() {
		this.apiService.invalidateCache('roles');
	}
}
