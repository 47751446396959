import { Injectable } from '@angular/core';
import { ApiService } from "@nstep-common/core";
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ServerSettingsService {
	constructor(private apiService: ApiService) { }

	get(): Observable<any> {
		return this.apiService.get(Any, 'server-settings');
	}
}