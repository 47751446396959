import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { CreateMatrixDto, MatrixVersionHistoryDto, UpdateMatrixDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementMatrixService {

	constructor(private apiService: ApiService) { }

	createEntitlementMatrix(model: CreateMatrixDto): Observable<any> {
		return this.apiService.post(Any, 'entitlement-matrix', model);
	}

	updateEntitlementMatrix(matrixVersionId: number, model: UpdateMatrixDto): Observable<any> {
		return this.apiService.put(Any, `entitlement-matrix/${matrixVersionId}`, model);
	}

	deleteEntitlementMatrix(matrixId: number): Observable<any> {
		return this.apiService.deleteNoContent(`entitlement-matrix/${matrixId}`);
	}

	exportEntitlementMatrix(matrixId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-matrix/${matrixId}/export`, { observe: 'response', responseType: 'blob', params: { id: matrixId } });
	}

	importEntitlementMatrix(formData: FormData, matrixId: number): Observable<any> {
		return this.apiService.post(Any, `entitlement-matrix/${matrixId}/import`, formData);
	}

	getAllEntitlementMatrixVersions(headquarterId: number, getExpiredOnly = false): Observable<any> {
		return this.apiService.get(Any, `entitlement-matrix/${headquarterId}`, { observe: 'response', params: { getExpiredOnly } });
	}

	getEntitlementMatrixVersion(headquarterId: number, matrixId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-matrix/${headquarterId}/${matrixId}`);
	}

	getHeadquarterMatrixVersionFormat(matrixVersion: MatrixVersionHistoryDto): string {

		if (Object.keys(matrixVersion).length === 0) return '';

		if (formatDate(matrixVersion.endDate, 'yyyy/MM/dd', 'en') === '9999/12/31') {
			return `Version ${matrixVersion.version} (${formatDate(matrixVersion.startDate, 'dd/MM/yyyy', 'en')} - N/A)`;
		} else {
			return `Version ${matrixVersion.version} (${formatDate(matrixVersion.startDate, 'dd/MM/yyyy', 'en')} - ${formatDate(matrixVersion.endDate, 'dd/MM/yyyy', 'en')})`;
		}
	}
}
