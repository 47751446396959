import { ExtraValidator } from "@nstep-common/utils";
import { PosLocationModel, PosLocationSteps } from "@nstep-internal/pages";

export class PosLocationModelValidator extends ExtraValidator<PosLocationModel> {
	constructor() {
		super();

		this.ruleFor('posLocationName')
			.matches(/^[a-zA-Z0-9\- _]{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('countryId')
			.must(countryId => !!countryId)
			.withMessage('Country is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('telephone')
			.matches(/^(?=.*\d)[a-zA-Z0-9 _()+]{4,}$/)
			.withMessage('Phone number not valid!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('streetName')
			.matches(/^[a-zA-Z0-9 _\-]{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('buildingNumber')
			.matches(/^[a-zA-Z0-9 _\-]{2,50}$/)
			.withMessage('A valid input is required with the length between 2-50 characters!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('postalCode')
			.matches(/^[a-zA-Z0-9 _\-]{2,50}$/)
			.withMessage('A valid input is required with the length between 2-50 characters!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('cityOrTown')
			.matches(/^[a-zA-Z0-9 _\-]{2,50}$/)
			.withMessage('A valid input is required with the length between 2-50 characters!')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('openingHour')
			.must(openingHour => !!openingHour)
			.withMessage('Opening hour is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('closingHour')
			.must(closingHour => !!closingHour)
			.withMessage('Closing hour is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('posLocationTypeIds')
			.must(posLocationTypeIds => !!posLocationTypeIds && posLocationTypeIds.length > 0)
			.withMessage('Location type is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('headquarterId')
			.must(headquarterId => !!headquarterId)
			.withMessage('Headquarter is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosLocationInformation);

		this.ruleFor('commonDateHolder')
			.must(commonDateHolder => !!commonDateHolder)
			.withMessage('Date is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.HeadquarterAssociation);

		this.ruleFor('entitlementTypeHeadquarterId')
			.must(entitlementTypeHeadquarterId => !!entitlementTypeHeadquarterId)
			.withMessage('Headquarter is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.AssignEntitlementTypes);

		this.ruleFor('entitlementTypeId')
			.must(entitlementTypeId => !!entitlementTypeId)
			.withMessage('Entitlement type is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.AssignEntitlementTypes);

		this.ruleFor('posOperatorsAssociations')
			.must(posOperatorsAssociations => posOperatorsAssociations.some(poa => poa.isFastScan))
			.withMessage('When POS Location Fast Scan is enabled a default operator with Fast Scan is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosOperators && model.isFastScan);

		this.ruleFor('amisCardNumber')
			.must(amisCardNumber => !!amisCardNumber)
			.withMessage('Amis card number is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator);

		this.ruleFor('posOperatorStartDate')
			.must(posOperatorStartDate => !!posOperatorStartDate)
			.withMessage('Start date is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator);

		this.ruleFor('posOperatorEndDate')
			.must(posOperatorEndDate => !!posOperatorEndDate)
			.withMessage('End date is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator && model.displayPOCForm);

		this.ruleFor('posOperatorContactName')
			.must(posOperatorContactName => !!posOperatorContactName)
			.withMessage('Name is required')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosOperatorContact || (model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator && model.displayPOCForm));

		this.ruleFor('posOperatorContactEmail')
			.must(posOperatorContactEmail => !!posOperatorContactEmail)
			.withMessage('Email is required')
			.emailAddress()
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosOperatorContact || (model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator && model.displayPOCForm));

		this.ruleFor('posOperatorContactPhoneNumber')
			.must(posOperatorContactPhoneNumber => !!posOperatorContactPhoneNumber)
			.withMessage('Phone number is required')
			.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
			.withMessage('Phone number is not valid')
			.when(model => model.groupPropertiesValidation === PosLocationSteps.PosOperatorContact || (model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator && model.displayPOCForm));

		this.ruleFor('isPrimaryPosOperatorContact')
			.must(isPrimaryPosOperatorContact => !!isPrimaryPosOperatorContact && isPrimaryPosOperatorContact)
			.withMessage('Primary contact is required')
			.when(model => (model.groupPropertiesValidation === PosLocationSteps.PosOperatorContact && model.noPrimaryContactSelected) || (model.groupPropertiesValidation === PosLocationSteps.EnrollPosOperator && model.displayPOCForm && model.noPrimaryContactSelected));
	}
}