import { Component, OnInit, ViewChild } from '@angular/core';

import { PagedQueryParameter, PaginationModel, createProxy, toast } from '@nstep-common/utils';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { DropdownOption, ModalComponent, } from '@nstep-common/semantic-ui';

import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { PosDeviceModel, PosDeviceModelValidator, ShortPosLocationInformationDto } from '@nstep-internal/pages';
import { PosDeviceService, PosLocationService } from '@nstep-internal/shared';

@Component({
	selector: 'app-pos-devices',
	templateUrl: './pos-devices.component.html'
})
export class PosDevicesComponent extends BaseComponent implements OnInit {
	@ViewChild('posDevicesModal') posDevicesModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	editModal: boolean = false;
	action: string = '';

	modalIsLoading = false;

	errors: string[] = [];

	pagination = new PaginationModel();

	pagedQueryModel: PagedQueryParameter = {
		itemsPerPage: 100,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	};

	tableColumns: TableColumn[] = [
		{ name: 'POS Device Name', key: 'posDeviceName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Device Identifier', key: 'deviceIdentifier', isHeaderCentered: true },
		{ name: 'POS Location Name', key: 'posLocationName', isHeaderCentered: true },
		{ name: 'Action', isCellCentered: true, isHeaderCentered: true }
	];

	posLocations: ShortPosLocationInformationDto[] = [];
	posLocationsDropdownValues: DropdownOption[] = [];
	posLocationsDropdownDataReady = false;

	validation: ValidationErrors<PosDeviceModel> = {};
	isValid: boolean = false;

	posDevice: PosDeviceModel = createProxy(new PosDeviceModel(), {
		set: () => this.validate(this.posDevice)
	});

	validate(value: PosDeviceModel): void {
		const validator = new PosDeviceModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private posDevicesService: PosDeviceService,
		private posLocationsService: PosLocationService) {
		super();
	}

	ngOnInit(): void {
		this.initializeTable();
		this.getPosLocations();
	}

	initializeTable(): void {
		this.tableDataReady = false;
		this.tableData = [];
		this.subscriptions.push(
			this.posDevicesService.getPosDevices(this.pagedQueryModel).subscribe({
				next: response => {

					this.tableData = chain(response.body)
						.map(e => ({
							id: e.id,
							posDeviceName: e.posDeviceName,
							deviceIdentifier: e.deviceIdentifier,
							posLocationName: e.posLocationName,
							posLocationId: e.posLocationId,
							isActive: e.isActive,
							action: e.isActive ? 'Disable' : 'Enable',
						}))
						.value();

					this.tableDataReady = true;
					this.modalIsLoading = false;
				},
				error: () => {
					this.tableDataReady = true;
					this.modalIsLoading = false;
				}
			})
		);
	}

	initializePosLocationDropdown(): void {
		this.posLocationsDropdownValues = chain(this.posLocations)
			.map(e => new DropdownOption({
				value: e.id,
				name: e.name
			}))
			.orderBy(e => e.name)
			.value();
	}

	getPosLocations(): void {
		this.subscriptions.push(
			this.posLocationsService.getAllPosLocations().subscribe({
				next: (response: ShortPosLocationInformationDto[]) => {
					this.posLocations = response;

					this.posLocationsDropdownDataReady = true;
				},
				error: () => {
					this.posLocationsDropdownDataReady = true;
				}
			})
		);
	}

	openAddPosDeviceModal(): void {
		this.editModal = false;
		this.initializePosLocationDropdown();

		this.posDevice = createProxy(new PosDeviceModel(), {
			set: () => this.validate(this.posDevice)
		});

		this.validate(this.posDevice);

		this.posDevicesModal.toggle();
	}

	search(): void {
		this.initializeTable();
	}

	onUserAction(item: any, action: string): void {
		this.editModal = false;
		this.action = action;

		const selectedItem: PosDeviceModel = {
			id: item.id,
			posLocationId: item.posLocationId,
			posLocationName: item.posLocationName,
			isActive: item.isActive,
			posDeviceName: item.posDeviceName,
			deviceIdentifier: item.deviceIdentifier
		};

		this.posDevice = createProxy(selectedItem, {
			set: () => this.validate(this.posDevice)
		});

		if (this.action === 'Edit') {
			this.editModal = true;
			this.initializePosLocationDropdown();
			this.validate(this.posDevice);
			this.posDevicesModal.toggle();

			return;
		}

		this.posDevice.isActive = !item.isActive;

		this.subscriptions.push(
			this.posDevicesService.updatePosDevice(this.posDevice).subscribe({
				next: () => {
					this.tableDataReady = false;
					this.tableData = [];

					this.posDevicesService.clearGetPosDevicesCache();
					this.initializeTable();

					toast('Success', `POS Device ${this.posDevice.posDeviceName} successfully modified!`, 'green');
				},
				error: () => {
					toast('Error', `POS Device ${this.posDevice.posDeviceName} could not be modified!`, 'red');
				}
			})
		);
	}

	close() {
		this.posDevicesModal.toggle();
		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.posDevice.isActive = true;
		this.modalIsLoading = true;

		if (this.editModal) {
			this.subscriptions.push(
				this.posDevicesService.updatePosDevice(this.posDevice).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.posDevicesService.clearGetPosDevicesCache();
						this.initializeTable();

						toast('Success', `POS Device ${this.posDevice.posDeviceName} successfully modified!`, 'green');
						this.posDevicesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `POS Device ${this.posDevice.posDeviceName} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.posDevicesService.createPosDevice(this.posDevice).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.posDevicesService.clearGetPosDevicesCache();
						this.initializeTable();

						toast('Success', `POS Device ${this.posDevice.posDeviceName} saved!`, 'green');
						this.posDevicesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `POS Device ${this.posDevice.posDeviceName} could not be saved!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}
}
